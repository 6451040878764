import { globalAPI } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"
import { GLOBAL_API_ROUTES } from "./routes"

export const globalsAPI = {
    sendMail: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.SEND_EMAIL,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    generateTempVideoToken: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.GENERATE_TEMP_VIDEO_TOKEN,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    submitCourseAssessment: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.SUBMIT_COURSE_ASSESSMENT,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    generateCourseCertificate: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.GENERATE_COURSE_CERTIFICATE,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    createStudent: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.CREATE_STUDENT,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    courseEnroll: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.COURSE_ENROLL,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    privateCodeValidation: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.PRIVATE_CODE_VALIDATION,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    addLiveReaction: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.ADD_REACTION,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    popLiveReaction: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.POP_REACTION,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    getSearchFacets: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.SEARCH_FACETS,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res.data
    },
    search: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.SEARCH,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res.data
    },
    createShareLink: async function (data, cancel = false) {
        let res = await globalAPI.request({
            url: GLOBAL_API_ROUTES.CREATE_SHARE_LINK,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res.data
    },
}

// defining the cancel API object for globalsAPI
const cancelApiObject = defineCancelApiObject(globalsAPI)
