import { createContext, useEffect, useRef, useState } from "react";
// import InterestPopup from "../../components/InterestPopup";
import * as Clevertap from "../../Clevertap/events";
import { analytics, auth, database } from "../../firebase/firebase";
import ScoreManager from "../../Managers/ScoringManager/post";
import UserManager from "../../Managers/Users";
import UserMetadataManager from "../../Managers/Users/Metadata";
import {
  exceuteUserLoginHook,
  resetUserLoginHook,
} from "../../utils/UserLoginHook";

const indexDB_Name = "d_user";
const indexDB_Version = 3;

const getIndexDB = () => {
  return (
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB
  );
};
const createStores = (table, event) => {
  let db = event.target.result;
  var oldVersion = event.oldVersion;
  var newVersion = event.newVersion;
  if (oldVersion < newVersion) {
    db.createObjectStore(table, {
      keyPath: "uid",
      autoIncrement: true,
    });
  }
};

export const UserContext = createContext();

export default function UserContextProvider(props) {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userAuth"))
  );
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chatSectionStatus, setChatSectionStatus] = useState(false);
  const [userCustomClaims, setUserCustomClaims] = useState(null);

  const [userMetadata, setUserMetadata] = useState(
    JSON.parse(localStorage.getItem("userMetadata"))
  );
  const [userBasicDetails, setUserBasicDetails] = useState(
    JSON.parse(localStorage.getItem("userBasicDetails"))
  );

  const [showInterest, setShowInterest] = useState(false);
  const [isUserOTPVerified, setIsUserOTPVerified] = useState(false);
  const CheckMarketing = useRef(true);
  const otpListenerRef = useRef(null);
  const otpListenerDocRef = useRef(null);

  useEffect(() => {
    const updateLoginDate = async () => {
      if (user && user?.uid && isUserOTPVerified) {
        const storedDate = localStorage.getItem("lastLogin");
        const today = new Date().toISOString().split("T")[0]; // Get today's date

        if (storedDate === today) {
          //   console.log("Last visit is already updated in localStorage.");
          return;
        }

        // console.log(
        //   "LocalStorage date is outdated or missing. Checking Firebase..."
        // );
        try {
          const lastLogin = await database
            .ref(`userScoring/${user.uid}/lastLogin`)
            .once("value");

          if (lastLogin.exists()) {
            const lastLoginDate = lastLogin.val(); // "YYYY-MM-DD" format

            if (lastLoginDate === today) {
              //   console.log(
              //     "The last login date in Firebase is already updated!"
              //   );
              localStorage.setItem("lastLogin", today); // Sync localStorage with Firebase
              return;
            }
          }
          await ScoreManager.dailyVisit(user.uid);
          // Update Firebase and localStorage
          //   console.log("Updating last login date in Firebase and localStorage.");
          await database.ref(`userScoring/${user.uid}/lastLogin`).set(today);
          localStorage.setItem("lastLogin", today);
        } catch (error) {
          console.error(
            "Error updating or fetching lastLogin from Firebase:",
            error
          );
        }
      }
    };

    updateLoginDate(); // Immediately invoke the async function
  }, [user, isUserOTPVerified]);

  useEffect(() => {
    if (user && user?.uid && window?.clientId) {
      // bypass login otp check for local
      if (window.location.origin.startsWith("http://localhost:")) {
        setIsUserOTPVerified(true);
        return;
      }
      if (!otpListenerRef.current) {
        setLoading(true);
        checkUserIsOTPVerified(
          user.uid,
          window?.clientId?.toString().replaceAll(".", ""),
          (res) => {
            setIsUserOTPVerified(res);
            setLoading(false);
          }
        );
      }
    }
  }, [user, window?.clientId]);

  useEffect(() => {
    resetUserLoginHook();
    let clevertapId = Clevertap.getClevertapId();
    Clevertap.askNotificationPermission();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let tokenResult = await user.getIdTokenResult();
        if (tokenResult && tokenResult.claims) {
          setUserCustomClaims(tokenResult.claims);
        }
        // console.log("creationTime", user.metadata.a);
        // console.log("lastSignInTime", user.metadata.b);
        localStorage.setItem("userAuth", JSON.stringify(user));
        CheckMarketing.current = true;
        // ask notification permissions firebase
        // AuthManager.askForPermissionToReceiveNotifications(user);
        // #region
        // add user data in index db
        // let indbUserData = {
        //   uid: user.uid,
        //   displayName: user.displayName,
        // };
        // addUserToIDB("d_user", indbUserData, (res) => { });
        // #endregion

        let firstCheckDone = false;

        //disconnect user state update

        UserMetadataManager._get.attachUserMetadata(user.uid, (data) => {
          if (!firstCheckDone) {
            if (
              !data.hasOwnProperty("areaOfInterest") &&
              !window.location.pathname.includes("/webinar/")
            ) {
              setShowInterest(true);
            }
            firstCheckDone = true;
          }
          localStorage.setItem("userMetadata", JSON.stringify(data));
          setUserMetadata(data);
        });

        UserManager._get.attachUserListener(user.uid, (data, err) => {
          if (err) {
            return;
          }
          let obj = {
            ...data,
            uid: user.uid,
          };
          localStorage.setItem("userBasicDetails", JSON.stringify(obj));

          let _obj = {
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            firstName: data?.firstName ?? "",
            lastName: data?.lastName ?? "",
            profession: data?.profession ?? "",
            speciality: data?.speciality ?? "",
            terms: `${data?.terms}` ?? "",
            marketing: `${data?.marketing}` ?? "",
            date: `${data?.date}` ?? "",
            clevertapId: `${clevertapId}`,
            loyaltyPoints: `${obj?.score || 0}`,
          };
          if (obj["employeeCode"]) {
            _obj["employeeCode"] = obj["employeeCode"];
          }
          analytics.setUserProperties(_obj);
          let LoyaltyPointsFromClevertap =
            Clevertap.getUserAttribute("Loyalty Points");
          if (
            !isNaN(LoyaltyPointsFromClevertap) &&
            obj?.score &&
            obj.score != LoyaltyPointsFromClevertap
          ) {
            Clevertap.onUserProfilePushWithSomeProperty({
              "Loyalty Points": obj.score,
            });
          }

          Clevertap.onUserLogin(user, obj);
          setUserBasicDetails(obj);
        });
        analytics.setUserId(user?.uid);
        setUser(user);
        exceuteUserLoginHook().then(() => resetUserLoginHook());
      } else {
        otpListenerDocRef.current?.off("value", otpListenerRef.current);
        otpListenerDocRef.current = null;
        otpListenerRef.current = null;
        resetUserLoginHook();
        // deleteUserToIDB("user", (res) => { });
        let PERSIST_LOCAL_STORAGE_KEYS = ["WZRK_", "firebase", "@firebase"];
        let keysToKeep = [];

        for (let key in localStorage) {
          if (
            PERSIST_LOCAL_STORAGE_KEYS.some((prefix) => key.startsWith(prefix))
          ) {
            keysToKeep.push(key);
          }
        }

        for (let key in localStorage) {
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }
        // sessionStorage.clear();
        // localStorage.clear();
        UserMetadataManager._get.removeMetadataListener();
        UserManager._get.removeUserListener();
        setUser(null);
        setUserBasicDetails(null);
        setUserMetadata(null);
        setUserCustomClaims(null);
        analytics.setUserId("");
        analytics.setUserProperties({});
      }
    });
  }, []);

  const addUserToIDB = (tableName, data, cb) => {
    let indb = getIndexDB();
    if (!indb) {
      console.log(
        "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."
      );
      return;
    } else {
      var db = null;
      var request = indb.open(indexDB_Name, indexDB_Version);

      request.onupgradeneeded = (event) => {
        db = event.target.result;
        createStores(tableName, event);
      };

      request.onerror = (event) => {
        // Do something with request.errorCode!
        console.log(
          "Why didn't you allow my web app to use IndexedDB?!",
          event
        );
        cb(false);
      };

      request.onsuccess = (event) => {
        db = event.target.result;
        db.onversionchange = (e) => {
          if (e.newVersion === null) {
            // An attempt is made to delete the db
            e.target.close(); // Manually close our connection to the db
          }
        };

        let tx = db.transaction([tableName], "readwrite");
        const store = tx.objectStore(tableName);
        store.add(data);
        request.result.close();
        cb(true);
      };
    }
  };

  const deleteUserToIDB = (tableName, cb) => {
    let indb = getIndexDB();
    if (!indb) {
      console.log(
        "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."
      );
      return;
    } else {
      var db = null;
      var request = indb.open(indexDB_Name, indexDB_Version);

      request.onerror = (event) => {
        // Do something with request.errorCode!
        console.log(
          "Why didn't you allow my web app to use IndexedDB?!",
          event
        );
        cb(false);
      };

      request.onsuccess = (event) => {
        db = event.target.result;
        db.onversionchange = (e) => {
          if (e.newVersion === null) {
            // An attempt is made to delete the db
            e.target.close(); // Manually close our connection to the db
          }
        };
        if (db.objectStoreNames.length > 0) {
          let tx = db.transaction([tableName], "readwrite");
          const store = tx.objectStore(tableName);
          store.clear();
          request.result.close();
        }
        cb(true);
      };
    }
  };

  const checkUserIsOTPVerified = (
    userId = "",
    deviceId = "",
    cb = () => {}
  ) => {
    try {
      let verified = false;
      if (!userId || !deviceId) {
        cb(verified);
        return;
      }
      otpListenerDocRef.current = database.ref(`OtpVerificationV2/${deviceId}`);
      otpListenerRef.current = otpListenerDocRef.current?.on(
        "value",
        (snapshot) => {
          if (!snapshot.exists()) {
            cb(verified);
            return;
          } else {
            let _data = snapshot.val();
            cb(
              (_data?.otp_verified &&
                _data.userId &&
                _data.userId === userId) ||
                false
            );
            return;
          }
        }
      );
    } catch (error) {
      console.error("Error :: ", error);
      cb(false);
      return;
    }
  };

  const toggleChatSection = (status) => {
    setChatSectionStatus(status);
  };
  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        isUserOTPVerified,
        userMetadata,
        userBasicDetails,
        setShowInterest,
        isChatBotOpen,
        setIsChatBotOpen,
        chatSectionStatus,
        toggleChatSection,
        userCustomClaims,
      }}
    >
      {/* {showInterest && <InterestPopup />} */}
      {props.children}
    </UserContext.Provider>
  );
}
