export const ScorePoints = {
  LIKE: 1,
  RATE: 1,
  SHARE: 15,
  VIDEO_COMPLETE: 10,
  PODCAST_COMPLETE: 10,
  WEBINAR_ATTENDED: 50,
  FEEDBACK: 10,
  NETWORKING: 5,
  APP_LOGIN: 100,
  COURSE_ENROLLEMENT: 20,
  DAILY_VISIT: 1,
  STORY_WATCHED: 5,
  REEL_WATCHED: 5,
};

export const Badges = {
  CAREGIVER: 50,
  DEFENDER: 100,
  GUARDIAN: 250,
  MASTER: 500,
  CHAMPION: 1000,
  WIZARD: 1500,
  ORACLE: 2500,
};
