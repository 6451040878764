const VERSIONS = {
  V1: "v1",
};

export const USER_API_ROUTES = {
  USER_CREATE: `/${VERSIONS.V1}/userlocation`,
  USER_UPDATE: `/${VERSIONS.V1}/user/update`,
  USER_OTP_SEND: `/${VERSIONS.V1}/otp/send`,
  USER_OTP_VERIFY: `/${VERSIONS.V1}/otp/verify`,
  USER_WEBINAR_REGISTER: `/${VERSIONS.V1}/webinar/register`,
  GET_USERS_IN_AREA: `/${VERSIONS.V1}/webinar/services/networking`,
};
export const GLOBAL_API_ROUTES = {
  SEND_EMAIL: `/${VERSIONS.V1}/share/mail`,
  GENERATE_TEMP_VIDEO_TOKEN: `/${VERSIONS.V1}/assets/token/tempgenerate`,
  SUBMIT_COURSE_ASSESSMENT: `/${VERSIONS.V1}/academy/assessment/submit`,
  CREATE_STUDENT: `/${VERSIONS.V1}/academy/student/create`,
  COURSE_ENROLL: `/${VERSIONS.V1}/academy/course/enroll`,
  GENERATE_COURSE_CERTIFICATE: `/${VERSIONS.V1}/academy/certificate`,
  ADD_REACTION: `/${VERSIONS.V1}/webinar/reaction/add`,
  POP_REACTION: `/${VERSIONS.V1}/webinar/reaction/pop`,
  PRIVATE_CODE_VALIDATION: `/${VERSIONS.V1}/privatecode`,
  SEARCH_FACETS: `/${VERSIONS.V1}/search/facets`,
  SEARCH: `/${VERSIONS.V1}/search`,
  CREATE_SHARE_LINK: `/${VERSIONS.V1}/dynamiclinks/generate`,
};
