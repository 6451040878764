import { createContext, useState, useEffect, useContext } from "react";
import UserMediaMetadataManager from "../../Managers/Users/UserMediaMetadata";
import { UserContext } from "../UserContext/UserContextProvider";
import CourseProgressionManager from "../../Managers/Academy/Progression";
import firebase from "../../firebase/firebase";

export const MediaMetadataContext = createContext([]);

export default function MediaMetadataContextProvider(props) {
  const { user } = useContext(UserContext);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!user) {
      return;
    }
    getMediaMetaData();
  }, [user]);

  const getMediaMetaData = async () => {
    let __data = await UserMediaMetadataManager._get.getUserAllMediaMetadata(
      user.uid
    );
    setData(__data);
  };

  const updateMediaMetadata = async (
    targetId,
    lastWatchTimestamp,
    totalDuration,
    targetType,
    targetData
  ) => {
    try {
      if (!user) {
        return;
      }
      await UserMediaMetadataManager._post.createUserMediaMetadata({
        userId: user.uid,
        targetId,
        lastWatchTimestamp,
        totalDuration,
        targetType,
        targetData: targetData || null,
      });
      setData((prev) => {
        let i = { ...prev };
        i[targetId] = {
          targetId,
          lastWatchTimestamp,
          totalDuration,
          targetType,
          targetData,
        };
        return i;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateCourseProgression = async (
    courseID,
    lastWatchTimestamp,
    totalDuration,
    targetData,
    isCompleted,
    firstTimeCompleteCallback
  ) => {
    try {
      if (!user || !courseID || !targetData) {
        return;
      }
      await CourseProgressionManager._put.updateChapterProgression({
        id: `${courseID}_${user.uid}`,
        studentId: user.uid,
        chapterId: targetData.id,
        courseId: courseID,
        data: {
          completed: isCompleted,
          completeDuration: lastWatchTimestamp,
          totalDuration: totalDuration,
          updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        },
        firstTimeCompleteCallback,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MediaMetadataContext.Provider
      value={{
        mediaMetadata: data,
        updateMediaMetadata,
        updateCourseProgression,
      }}
    >
      {props.children}
    </MediaMetadataContext.Provider>
  );
}
