import {
  WEBINAR_ATTENDED_COLLECTION
} from "../../AppConstants/CollectionConstants";
import firebase, { firestore } from "../../firebase/firebase";
import { getSystemID } from "../../utils/getPlatformInfo";

const webinarTimeSpentAnalytics = async (
  userID,
  webinarID,
  prevAgendaId,
  currentAgendaID,
) => {
  try {
    let docId = `${webinarID}_${userID}`;
    const webinarTimespentRef = firestore
      .collection(WEBINAR_ATTENDED_COLLECTION)
      .doc(docId);

    let systemID = getSystemID();

    // next agenda start time else webinar end time
    await firestore.runTransaction(async (transaction) => {
      let previousDoc = await transaction.get(webinarTimespentRef);
      let timeSessionData = [];
      let attendedAgendas = new Set();

      if (!previousDoc.exists) {
        if (prevAgendaId) {
          attendedAgendas.add(prevAgendaId);
          timeSessionData.push({
            agendaID: prevAgendaId,
            startTime: firebase.firestore.Timestamp.now(),
            endTime: firebase.firestore.Timestamp.now(),
            systemID: systemID
          });
        }

        if (currentAgendaID) {
          attendedAgendas.add(currentAgendaID);
          timeSessionData.push({
            agendaID: currentAgendaID,
            startTime: firebase.firestore.Timestamp.now(),
            endTime: null,
            systemID: systemID
          });
        }

        transaction.set(webinarTimespentRef, {
          data: firebase.firestore.FieldValue.arrayUnion(...attendedAgendas),
          id: docId,
          userID: userID,
          webinarID: webinarID,
          timestamp: firebase.firestore.Timestamp.now(),
          timeSessionData: timeSessionData,
        });

        return;
      }

      let previousData = previousDoc.data();
      timeSessionData = previousData['timeSessionData'];
      attendedAgendas = new Set(previousData['data']);

      if (prevAgendaId) {
        attendedAgendas.add(prevAgendaId);
        let agendaIndex = timeSessionData.findIndex(
          (d) => d.agendaID === prevAgendaId && d.systemID === systemID
        );

        // No data for previous agenda found - set base data
        if (agendaIndex === -1) {
          timeSessionData.push({
            agendaID: prevAgendaId,
            startTime: firebase.firestore.Timestamp.now(),
            endTime: firebase.firestore.Timestamp.now(),
            systemID: systemID
          });
        } else {
          timeSessionData[agendaIndex] = {
            ...timeSessionData[agendaIndex],
            endTime: firebase.firestore.Timestamp.now(),
          }
        }
      };

      if (currentAgendaID) {
        attendedAgendas.add(currentAgendaID);
        let agendaIndex = timeSessionData.findIndex(
          (d) => d.agendaID === currentAgendaID && d.systemID === systemID
        );

        // No data for current agenda found - most likely - set the base data
        if (agendaIndex === -1) {
          timeSessionData.push({
            agendaID: currentAgendaID,
            startTime: firebase.firestore.Timestamp.now(),
            endTime: null,
            systemID: systemID
          });
        } else {
          // Well, this is a case when same agenda was reactivated. do not do anything, new agenda switch will take care of it
        }
      }

      // Lastly update the whole document
      return transaction.update(webinarTimespentRef, {
        data: firebase.firestore.FieldValue.arrayUnion(...attendedAgendas),
        timeSessionData,
        updateTimestamp: firebase.firestore.Timestamp.now(),
      })
    });
  } catch (error) {
    console.error(error)
  }
};

const post = {
  webinarTimeSpentAnalytics,
};

export default post;
